import { Dialog, Transition } from '@headlessui/react'
import React, { FC, Fragment } from 'react'

interface CustomDialogProps {
	open: boolean
	onClose: (v: boolean) => void
}
const CustomDialog: FC<CustomDialogProps> = ({ open, onClose, children }) => (
	<Transition.Root show={open} as={Fragment}>
		<Dialog
			as="div"
			open={open}
			onClose={onClose}
			className="fixed z-10 inset-0 overflow-y-auto"
		>
			<div className="flex items-center justify-center min-h-screen p-3 sm:p-0">
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{children}
				</Transition.Child>
			</div>
		</Dialog>
	</Transition.Root>
)

export default CustomDialog
