import { useFormik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import { mutate } from 'swr'
import { login } from '../../adapters/auth'
import PrimaryButton from '../../components/Button'
import Input from '../../components/Input'
import { useToastContext } from '../../contexts/toastContext'

const Login = () => {
	const history = useHistory()
	const { addToast } = useToastContext()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await login(values)

				if (res.token) {
					addToast({
						variant: 'success',
						message: res.message,
					})

					localStorage.setItem('x-token', res.token)
					mutate('currentUser')
					history.push('/dashboard')
				}
			} catch ({ message }) {
				addToast({
					variant: 'error',
					message: message,
				})
			}
		},
	})

	return (
		<div className="min-h-screen bg-gray-50 p-6 flex flex-col justify-center">
			<div className="mx-auto w-full max-w-md">
				<h2 className="text-center text-3xl font-extrabold text-gray-900">Central289</h2>
			</div>

			<div className="mt-6 mx-auto w-full max-w-md">
				<div className="bg-white p-6 rounded-lg">
					<form className="space-y-4" autoComplete="off" onSubmit={handleSubmit}>
						<Input
							type="text"
							label="Username"
							name="username"
							required
							onChange={handleChange}
						/>
						<Input
							type="password"
							label="Password"
							name="password"
							required
							onChange={handleChange}
						/>

						<PrimaryButton type="submit" className="w-full" disabled={isSubmitting}>
							Sign in
						</PrimaryButton>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Login
