import React, { useState, useEffect } from 'react'
import Stat from '../../components/Stat'
import { getSummary, getBalance } from '../../adapters/stat'
import { getAllSCBs } from '../../adapters/scb'
import useSWR from 'swr'
import { DatePicker, Space, Select } from 'antd';
import moment from 'moment';
import Button from '../../components/Button'

const StatList = () => {
    // Datepicker
    const { RangePicker } = DatePicker;
    const formatDate = 'YYYY-MM-DD';
    const defaultDates = [moment().subtract(7, 'day'), moment()]

    // Selete
    const { Option } = Select;

    // DataState
    const [dates, setDates] = useState([''])
    const [updateDate, setUpdateDate ] = useState([
        defaultDates[0].format(formatDate),
        defaultDates[1].format(formatDate),
    ]);

    // SeleteState
    const [account, setAccount] = useState('')

    // Handling
    const handleRangePicker = (dates: any, dateStrings: any) => setDates(dateStrings)
    const handleUpdateDate = () => setUpdateDate(dates)
    const haddleAccount = (value: any) => setAccount(value)

    // Fetch Data
    const { data: scb } = useSWR('/v1/scb', getAllSCBs)
    const { data: summary } = useSWR(`/v1/summary?start=${updateDate[0]}&end=${updateDate[1]}&accNo=${account}`, getSummary)
    const { data: balance } = useSWR(`/v1/summary/${account}/balance`, getBalance)

    // Deafault Value
    useEffect(() => {
        if (scb) setAccount(scb?.data[0].accountNumber)
    }, [scb])

    // Loop Element
    const optionScb = scb?.data.map((account, index) => (
        <Option value={account?.accountNumber} key={index}>{account?.accountName}</Option>
    ))

    return (
        <>
            <section className="space-y-10 md:space-y-0 mb-3 lg:mb-6">
                <div className="md:flex items-center justify-between">
                    <div className="flex mb-3 lg:mb-0">
                        <Space direction="vertical" size={12}>
                            <RangePicker
                                size="large"
                                defaultValue={[defaultDates[0], defaultDates[1]]}
                                onChange={handleRangePicker}
                            />
                        </Space>
                        <Button type="button" className="ml-3" onClick={handleUpdateDate}>
                            ค้นหา
                        </Button>
                    </div>
                    <div className="flex items-center">
                        <span className="block mr-3" style={{ fontSize: '16px' }}>บัญชี</span>
                        <Select
                            value={account}
                            size="large"
                            onChange={haddleAccount}
                            style={{minWidth: '200px'}}
                        >
                            {optionScb}
                        </Select>
                    </div>
                </div>
            </section>
            <section className="space-y-0 grid grid-cols-2 md:grid-cols-3 gap-x-4 md:gap-x-6 gap-y-4 md:gap-y-6 mb-4 md:mb-6">
                <Stat
                    textColor='text-indigo-600'
                    value={'฿ ' + (balance?.availableBalance ?? '0.00')}
                    title='ยอดเงินคงเหลือ'
                />
                <Stat
                    textColor='text-green-600'
                    value={'฿ ' + summary?.deposit}
                    title='ยอดโอนเข้า'
                />
                <Stat
                    textColor='text-green-600'
                    value={summary?.countDeposit +' รายการ'}
                    title='รายการโอนเข้า'
                />
                <Stat
                    textColor='text-red-600'
                    value={'฿ ' + summary?.withdraw}
                    title='ยอดโอนออก'
                />
                <Stat
                    textColor='text-red-600'
                    value={'฿ ' + summary?.charge}
                    title='ยอดโอนเบิกทุน'
                />
                <Stat
                    textColor='text-red-600'
                    value={summary?.countWithdraw +' รายการ'}
                    title='รายการโอนออก'
                />
            </section>
        </>
    )
}

export default StatList
