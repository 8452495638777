import { FC, useState } from 'react'
import { mutateMany } from 'swr-mutate-many'
import { deleteSCB } from '../../../adapters/scb'
import Button from '../../../components/Button'
import CustomDialog from '../../../components/Dialog/CustomDialog'
import { useToastContext } from '../../../contexts/toastContext'
import { SCB } from '../../../entities/scb'

interface DeleteSCBDialogProps {
	open: boolean
	onClose: () => void

	data: SCB | undefined
}
const DeleteSCBDialog: FC<DeleteSCBDialogProps> = ({ open, onClose, data }) => {
	const [loading, setLoading] = useState(false)

	const { addToast } = useToastContext()
	const onSubmitDelete = async () => {
		if (!data) {
			return false
		}

		setLoading(true)
		try {
			const res = await deleteSCB(data.id)

			addToast({
				message: res.message,
				variant: 'success',
			})

			setLoading(false)
			mutateMany('/v1/scb*')
			onClose()
		} catch ({ message }) {
			addToast({
				message: message,
				variant: 'error',
			})

			setLoading(false)
		}
	}

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">ลบ SCB API</h3>
					<h6 className="text-sm text-gray-600">
						คุณต้องการลบ SCB API <b>{data?.accountName}</b>?
					</h6>
				</div>

				<div className="grid grid-cols-2 gap-4 mt-5">
					<Button type="button" disabled={loading} onClick={() => onSubmitDelete()}>
						ยืนยัน
					</Button>
					<Button
						type="button"
						color="text-gray-900 border border-gray-200 hover:border-gray-300"
						onClick={() => onClose()}
					>
						ยกเลิก
					</Button>
				</div>
			</div>
		</CustomDialog>
	)
}

export default DeleteSCBDialog
