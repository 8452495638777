import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import React, { FC, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { UserInfo } from '../../entities/user'
import { isRoleAccess } from '../../helpers/role'
import { MenuItem, menuItems } from './Layout'

interface MobileSidebarItemProps {
	item: MenuItem
}
const MobileSidebarItem: FC<MobileSidebarItemProps> = ({ item }) =>
	item.to.startsWith('/') ? (
		<NavLink
			to={item.to}
			activeClassName="bg-indigo-800"
			className="flex items-center py-2 px-4 rounded-md text-white hover:bg-indigo-800"
		>
			<item.icon className="w-5 h-5" />
			<span className="ml-3">{item.text}</span>
		</NavLink>
	) : (
		<a
			rel="noreferrer"
			target="_blank"
			href={item.to}
			className="flex items-center py-2 px-4 rounded-md text-white hover:bg-indigo-800"
		>
			<item.icon className="w-5 h-5" />
			<span className="ml-3">{item.text}</span>
		</a>
	)

interface MobileSidebarProps {
	openSidebar: boolean
	setOpenSidebar: (v: boolean) => void

	user: UserInfo
}
const MobileSidebar: FC<MobileSidebarProps> = ({ openSidebar, setOpenSidebar, user }) => {
	return (
		<Transition.Root show={openSidebar} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed inset-0 flex z-40"
				open={openSidebar}
				onClose={() => setOpenSidebar(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</Transition.Child>
				<Transition.Child
					as={Fragment}
					enter="transition ease-in-out duration-300 transform"
					enterFrom="-translate-x-full"
					enterTo="translate-x-0"
					leave="transition ease-in-out duration-300 transform"
					leaveFrom="translate-x-0"
					leaveTo="-translate-x-full"
				>
					<div className="relative flex flex-1 flex-col max-w-xs w-full p-6 bg-indigo-600">
						<div className="flex flex-shrink-0 items-center justify-between">
							<span />
							<button className="flex items-center justify-center h-10 w-10 rounded-full focus:outline-none">
								<XIcon
									className="h-6 w-6 text-white"
									onClick={() => setOpenSidebar(false)}
								/>
							</button>
						</div>
						<div className="flex-1 overflow-y-auto mt-5">
							<nav className="divide-y divide-gray-200">
								<div className="space-y-1">
									{menuItems
										.filter((item) => isRoleAccess(user.role, item.role))
										.map((item) => (
											<MobileSidebarItem
												key={`mobile.${item.to}`}
												item={item}
											/>
										))}
								</div>
							</nav>
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition.Root>
	)
}

export default MobileSidebar
