import axios, { AxiosError } from 'axios'

const baseRequest = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080',
})

baseRequest.interceptors.response.use(
	(res) => res.data,
	(err: AxiosError) => {
		if (err.response?.data.message) {
			return Promise.reject(err.response.data)
		}
		if (err.message) {
			return Promise.reject({ message: err.message })
		}
		return Promise.reject({ message: 'Cannot connect to the server.' })
	}
)
baseRequest.interceptors.request.use((config) => {
	const token = localStorage.getItem('x-token')
	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`
	}

	return config
})

export default baseRequest

export interface BaseResponse {
	message: string
}

export interface ResourcePage<T> {
	data: T[]
	pagination: {
		limit: number
		rows: number
		pageSize: number
	}
}

export interface ResourceSummary {
    charge: string
    countDeposit: string
    countWithdraw: string
    deposit: string
    withdraw: string
}

export interface ResourceBalance {
    availableBalance: string
}

