import clsx from 'clsx'
import React, { FC, InputHTMLAttributes } from 'react'

interface InputProps {
	label?: string

	className?: string
}
const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	className,
	...props
}) => (
	<div>
		{label && <label className="block text-sm font-medium text-gray-700">{label}</label>}
		<div className={clsx({ 'mt-1': label })}>
			<input
				className={clsx(
					className,
					'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50'
				)}
				placeholder={label}
				{...props}
			/>
		</div>
	</div>
)

export default Input
