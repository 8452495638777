import { Transfer } from '../entities/transfer'
import api, { BaseResponse, ResourcePage } from './index'

export const getAllTransfers = (params: any) => {
	return api.get<void, ResourcePage<Transfer>>('/v1/transfers', { params: params })
}

interface CreateTransferPayload {
	type: string
	accountNumber: string
	amount: number
	remark: string
}
export const createTransfer = (payload: CreateTransferPayload) => {
	return api.post<void, BaseResponse>('/v1/transfers', payload)
}

interface ConfirmTransferPayload {
	fromAccount: number
}
export const confirmTransfer = (id: number, payload: ConfirmTransferPayload) => {
	return api.post<void, BaseResponse>(`/v1/transfers/${id}/confirm`, payload)
}

export const rejectTransfer = (id: number) => {
	return api.post<void, BaseResponse>(`/v1/transfers/${id}/reject`)
}
