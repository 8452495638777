import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchCurrentUser } from '../adapters/auth'
import Layout from '../components/Layout/Layout'
import { UserInfo } from '../entities/user'

interface IAuthContext {
	authInfo: UserInfo | undefined
	setAuthInfo: (v: UserInfo | undefined) => void
	signOut: () => void
}

export const AuthContext = createContext<IAuthContext>({
	authInfo: undefined,
	setAuthInfo: (_) => {},
	signOut: () => {},
})
export const useAuthContext = () => useContext(AuthContext)

export const AuthContextProvider: FC = ({ children }) => {
	const [authInfo, setAuthInfo] = useState<UserInfo | undefined>(undefined)

	const history = useHistory()
	const initAuthContext = {
		authInfo: authInfo,
		setAuthInfo: setAuthInfo,
		signOut: () => {
			setAuthInfo(undefined)
			localStorage.removeItem('x-token')

			history.push('/login')
		},
	}

	useEffect(() => {
		fetchCurrentUser()
			.then((res) => {
				setAuthInfo(res)
			})
			.catch(() => {
				setAuthInfo(undefined)
				localStorage.removeItem('x-token')

				history.push('/login')
			})
	}, [setAuthInfo, history])

	if (!authInfo) {
		return (
			<div className="flex flex-1 items-center justify-center min-h-screen">
				<div className="h-6 w-6 spinner border-2" />
			</div>
		)
	}
	return (
		<AuthContext.Provider value={initAuthContext}>
			<Layout>{children}</Layout>
		</AuthContext.Provider>
	)
}
