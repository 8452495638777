import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { UserInfo } from '../../entities/user'
import { isRoleAccess } from '../../helpers/role'
import { MenuItem, menuItems } from './Layout'

interface SidebarItemProps {
	item: MenuItem
}
const SidebarItem: FC<SidebarItemProps> = ({ item }) =>
	item.to.startsWith('/') ? (
		<NavLink
			to={item.to}
			activeClassName="bg-gray-200 text-gray-900"
			className="flex items-center py-2 px-4 rounded-md text-gray-600"
		>
			<item.icon className="w-5 h-5" />
			<span className="ml-3">{item.text}</span>
		</NavLink>
	) : (
		<a
			rel="noreferrer"
			target="_blank"
			href={item.to}
			className="flex items-center py-2 px-4 rounded-md text-gray-600"
		>
			<item.icon className="w-5 h-5" />
			<span className="ml-3">{item.text}</span>
		</a>
	)

interface SidebarProps {
	user: UserInfo
}
const Sidebar: FC<SidebarProps> = ({ user }) => (
	<div className="hidden lg:block col-span-2">
		<nav className="divide-y divide-gray-200">
			<div className="space-y-1">
				{menuItems
					.filter((item) => isRoleAccess(user.role, item.role))
					.map((item) => (
						<SidebarItem key={item.to} item={item} />
					))}
			</div>
		</nav>
	</div>
)

export default Sidebar
