import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { mutateMany } from 'swr-mutate-many'
import { getAllSCBs } from '../../../adapters/scb'
import { confirmTransfer } from '../../../adapters/transfer'
import Button from '../../../components/Button'
import CustomDialog from '../../../components/Dialog/CustomDialog'
import Select from '../../../components/Select'
import { useToastContext } from '../../../contexts/toastContext'
import { SCB } from '../../../entities/scb'
import { Transfer } from '../../../entities/transfer'
import { numberFormat } from '../../../helpers/number'

interface ConfirmTransferDialogProps {
	open: boolean
	onClose: () => void

	data: Transfer | undefined
}
const ConfirmTransferDialog: FC<ConfirmTransferDialogProps> = ({ open, onClose, data }) => {
	const [banks, setBank] = useState<SCB[] | undefined>(undefined)
	useEffect(() => {
		getAllSCBs({}).then((res) => {
			setBank(res.data)
		})
	}, [])

	const { addToast } = useToastContext()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			fromAccount: 0,
		},
		onSubmit: async (values) => {
			if (!data) {
				return false
			}
			if (values.fromAccount === 0) {
				addToast({
					message: 'กรุณาเลือกบัญชีสำหรับโอนเงิน',
					variant: 'error',
				})
				return false
			}

			try {
				const res = await confirmTransfer(data.id, {
					fromAccount: parseInt(values.fromAccount.toString()),
				})

				addToast({
					message: res.message,
					variant: 'success',
				})

				mutateMany('/v1/transfers*')
				onClose()
			} catch ({ message }) {
				addToast({
					message: message,
					variant: 'error',
				})
			}
		},
	})

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">ยืนยันรายการเบิก #{data?.id}</h3>
					<h6 className="text-sm text-gray-500">
						<span>
							โอนเงินไปยัง <b>{data?.to.accountName}</b>
						</span>
						<span>&nbsp; {numberFormat(data?.amount)} บาท</span>
					</h6>
				</div>
				<form className="space-y-3 mt-5" autoComplete="off" onSubmit={handleSubmit}>
					<Select name="fromAccount" label="โอนเงินจาก" required onChange={handleChange}>
						<option>--- เลือกบัญชี ---</option>
						{banks?.map((bank) => (
							<option key={`select-from.${bank.id}`} value={bank.id}>
								{bank.accountName} ({bank.accountNumber})
							</option>
						))}
					</Select>
					<div className="grid grid-cols-2 gap-4">
						<Button type="submit" disabled={isSubmitting}>
							ยืนยัน
						</Button>
						<Button
							type="button"
							color="text-gray-900 border border-gray-200 hover:border-gray-300"
							onClick={onClose}
						>
							ยกเลิก
						</Button>
					</div>
				</form>
			</div>
		</CustomDialog>
	)
}

export default ConfirmTransferDialog
