import { UserCircleIcon } from '@heroicons/react/solid'
import debounce from 'lodash/debounce'
import React, { Fragment, useState } from 'react'
import useSWR from 'swr'
import { getAllUsers } from '../../adapters/user'
import { default as Button, default as PrimaryButton } from '../../components/Button'
import Input from '../../components/Input'
import Pagination from '../../components/Pagination'
import { UserInfo } from '../../entities/user'
import CreateUserDialog from './CreateUserDialog'
import UpdateUserDialog from './UpdateUserDialog'

const UsersLanding = () => {
	const [query, setQuery] = useState('')
	const setDebounceQuery = debounce((v: string) => {
		setQuery(v)
	}, 500)

	const [page, setPage] = useState(1)
	const { data: res, mutate } = useSWR(`/v1/users?query=${query}&page=${page}`, () =>
		getAllUsers({
			query: query,
			page: page,
		})
	)

	// create user dialog
	const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false)

	// update user dialog
	const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false)
	const [toUpdateUser, setToUpdateUser] = useState<UserInfo | undefined>(undefined)
	const onUpdateUser = (user: UserInfo) => {
		setUpdateUserDialogOpen(true)
		setToUpdateUser(user)
	}

	const renderBadge = (user: UserInfo) => (
		<span className="bg-blue-100 text-blue-500 text-sm px-2 py-1 rounded-full">
			{user.role.charAt(0).toUpperCase()}
			{user.role.substr(1)}
		</span>
	)
	const renderUserRow = (user: UserInfo) => (
		<li key={user.id}>
			<div className="px-6 py-4 flex items-center justify-between">
				<div className="flex flex-1 items-center">
					<span>
						<UserCircleIcon className="w-6 h-6 text-blue-500" />
					</span>
					<div className="ml-4">
						<h3 className="text-blue-500 text-lg">{user.username}</h3>
						<h6 className="text-gray-500 text-sm">{user.name}</h6>
					</div>
				</div>
				<div className="flex-1">{renderBadge(user)}</div>
				<div>
					<Button
						type="button"
						className="ml-0"
						size="sm"
						color="bg-gray-600 hover:bg-gray-700"
						onClick={() => onUpdateUser(user)}
					>
						แก้ไข
					</Button>
				</div>
			</div>
		</li>
	)
	const renderTableContent = () => {
		if (!res) {
			return (
				<li>
					<div className="px-6 py-4 text-center">
						<div className="w-6 h-6 spinner border-2" />
					</div>
				</li>
			)
		}
		if (res.data.length <= 0) {
			return (
				<li>
					<div className="px-6 py-4 text-center">ไม่พบข้อมูล</div>
				</li>
			)
		}
		return res.data.map((user) => renderUserRow(user))
	}
	return (
		<Fragment>
			<main className="col-span-12 lg:col-span-5 space-y-3">
				<div className="bg-white border border-gray-200 rounded-md">
					<div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
						<h3 className="text-lg font-medium">ผู้ใช้งาน</h3>
						<div className="flex">
							<Input
								placeholder="Search"
								onChange={(e) => setDebounceQuery(e.currentTarget.value)}
							/>
							<PrimaryButton
								type="button"
								className="ml-3"
								onClick={() => setCreateUserDialogOpen(true)}
							>
								เพิ่มผู้ใช้งาน
							</PrimaryButton>
						</div>
					</div>
					<ul className="divide-y divide-gray-200">{renderTableContent()}</ul>
					{res?.pagination && (
						<Pagination data={res.pagination} page={page} setPage={setPage} />
					)}
				</div>
			</main>

			<CreateUserDialog
				open={createUserDialogOpen}
				onClose={setCreateUserDialogOpen}
				refresh={mutate}
			/>
			<UpdateUserDialog
				open={updateUserDialogOpen}
				onClose={setUpdateUserDialogOpen}
				user={toUpdateUser}
				refresh={mutate}
			/>
		</Fragment>
	)
}

export default UsersLanding
