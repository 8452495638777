import { useFormik } from 'formik'
import React, { FC } from 'react'
import { createUser } from '../../adapters/user'
import PrimaryButton from '../../components/Button'
import SecondaryButton from '../../components/Button/SecondaryButton'
import CustomDialog from '../../components/Dialog/CustomDialog'
import Input from '../../components/Input'
import { useToastContext } from '../../contexts/toastContext'

interface CreateUserDialogProps {
	open: boolean
	onClose: (v: boolean) => void

	refresh: () => void
}
const CreateUserDialog: FC<CreateUserDialogProps> = ({ open, onClose, refresh }) => {
	const { addToast } = useToastContext()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			name: '',
			username: '',
			password: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await createUser(values)

				addToast({
					variant: 'success',
					message: res.message,
				})
				refresh()
				onClose(false)
			} catch ({ message }) {
				addToast({
					variant: 'error',
					message: message,
				})
			}
		},
	})

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">เพิ่มผู้ใช้งาน</h3>
				</div>
				<form className="space-y-3" autoComplete="off" onSubmit={handleSubmit}>
					<Input type="text" label="ชื่อ" name="name" required onChange={handleChange} />
					<Input
						type="text"
						label="ชื่อผู้ใช้งาน"
						name="username"
						required
						onChange={handleChange}
					/>
					<Input
						type="text"
						label="รหัสผ่าน"
						name="password"
						required
						onChange={handleChange}
					/>
					<div className="grid grid-cols-2 gap-4">
						<PrimaryButton type="submit" disabled={isSubmitting}>
							ยืนยัน
						</PrimaryButton>
						<SecondaryButton type="button" onClick={() => onClose(false)}>
							ยกเลิก
						</SecondaryButton>
					</div>
				</form>
			</div>
		</CustomDialog>
	)
}

export default CreateUserDialog
