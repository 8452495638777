import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { mutateMany } from 'swr-mutate-many'
import { getBanks } from '../../../adapters/bank'
import { createTransfer } from '../../../adapters/transfer'
import Button from '../../../components/Button'
import CustomDialog from '../../../components/Dialog/CustomDialog'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import { useAuthContext } from '../../../contexts/authContext'
import { useToastContext } from '../../../contexts/toastContext'
import { Bank } from '../../../entities/bank'

interface CreateTransferDialogProps {
	open: boolean
	onClose: () => void
}
const CreateTransferDialog: FC<CreateTransferDialogProps> = ({ open, onClose }) => {
	const { authInfo } = useAuthContext()
	const { addToast } = useToastContext()
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			type: '',
			accountNumber: '',
			amount: 0,
			remark: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await createTransfer(values)

				addToast({
					message: res.message,
					variant: 'success',
				})

				mutateMany('/v1/transfers*')
				onClose()
			} catch ({ message }) {
				addToast({
					message: message,
					variant: 'error',
				})
			}
		},
	})

	const [banks, setBanks] = useState<Bank[]>([])
	useEffect(() => {
		getBanks({
			type: values.type,
			limit: 100,
		}).then((res) => {
			setBanks(res.data)
		})
	}, [values.type])

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">ทำรายการเบิก</h3>
				</div>
				<form className="space-y-3 mt-5" autoComplete="off" onSubmit={handleSubmit}>
					<Select name="type" label="ประเภท" required onChange={handleChange}>
						<option>--- เลือกประเภท ---</option>
						{authInfo?.access.game && <option value="game">Game</option>}
						{authInfo?.access.huay && <option value="huay">Huay</option>}
					</Select>
					<Select name="accountNumber" label="บัญชี" required onChange={handleChange}>
						<option>--- เลือกบัญชี ---</option>
						{banks.map((bank) => (
							<option key={`transfer-account.${bank.id}`} value={bank.accountNumber}>
								{bank.accountName} ({bank.accountNumber})
							</option>
						))}
					</Select>
					<Input
						type="number"
						name="amount"
						label="จำนวนเงิน"
						required
						onChange={handleChange}
					/>
					<Input
						type="text"
						name="remark"
						label="หมายเหตุ"
						required
						onChange={handleChange}
					/>
					<div className="grid grid-cols-2 gap-4">
						<Button type="submit" disabled={isSubmitting}>
							ยืนยัน
						</Button>
						<Button
							type="button"
							color="text-gray-900 border border-gray-200 hover:border-gray-300"
							onClick={onClose}
						>
							ยกเลิก
						</Button>
					</div>
				</form>
			</div>
		</CustomDialog>
	)
}

export default CreateTransferDialog
