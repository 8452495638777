import { useFormik } from 'formik'
import React, { FC } from 'react'
import { mutateMany } from 'swr-mutate-many'
import { confirmOTP } from '../../../adapters/scb'
import Button from '../../../components/Button'
import CustomDialog from '../../../components/Dialog/CustomDialog'
import Input from '../../../components/Input'
import { useToastContext } from '../../../contexts/toastContext'

interface ConfirmSCBDialogProps {
	open: boolean
	onClose: () => void

	deviceID: string
}
const ConfirmSCBDialog: FC<ConfirmSCBDialogProps> = ({ open, onClose, deviceID }) => {
	const { addToast } = useToastContext()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			otp: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await confirmOTP(deviceID, values)

				addToast({
					message: res.message,
					variant: 'success',
				})

				mutateMany('/v1/scb*')
				onClose()
			} catch ({ message }) {
				addToast({
					message: message,
					variant: 'error',
				})
			}
		},
	})

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">เพิ่ม SCB API</h3>
				</div>
				<form className="space-y-3 mt-5" autoComplete="off" onSubmit={handleSubmit}>
					<Input
						type="text"
						label="เลขบัตรประชาชน"
						name="identification"
						required
						onChange={handleChange}
					/>
					<div className="grid grid-cols-2 gap-4">
						<Button type="submit" disabled={isSubmitting}>
							ยืนยัน
						</Button>
						<Button
							type="button"
							color="text-gray-900 border border-gray-200 hover:border-gray-300"
							onClick={() => onClose()}
						>
							ยกเลิก
						</Button>
					</div>
				</form>
			</div>
		</CustomDialog>
	)
}

export default ConfirmSCBDialog
