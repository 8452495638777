import React, { FC, InputHTMLAttributes } from 'react'

interface CheckboxProps {
	label?: string
}
const Checkbox: FC<CheckboxProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	name,
	...props
}) => (
	<div className="flex items-center">
		<input
			type="checkbox"
			name={name}
			id={name}
			className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
			{...props}
		/>
		<label htmlFor={name} className="block ml-2 text-sm text-gray-900">
			{label}
		</label>
	</div>
)

export default Checkbox
