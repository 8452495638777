import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { mutateMany } from 'swr-mutate-many'
import { updateBank } from '../../../adapters/bank'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import CustomDialog from '../../../components/Dialog/CustomDialog'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import { useToastContext } from '../../../contexts/toastContext'
import { Bank } from '../../../entities/bank'
import { banks } from '../../../helpers/bank'

interface UpdateBankDialogProps {
	open: boolean
	onClose: () => void

	data: Bank | undefined
}
const UpdateBankDialog: FC<UpdateBankDialogProps> = ({ open, onClose, data }) => {
	const { addToast } = useToastContext()
	const { values, isSubmitting, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			enable: false,
			accountName: '',
			accountNumber: '',
			bankCode: '',
		},
		onSubmit: async (values) => {
			if (!data) {
				return false
			}

			try {
				const res = await updateBank(data.id, values)

				addToast({
					message: res.message,
					variant: 'success',
				})

				mutateMany('/v1/banks*')
				onClose()
			} catch ({ message }) {
				addToast({
					message: message,
					variant: 'error',
				})
			}
		},
	})
	useEffect(() => {
		if (data) {
			setFieldValue('enable', data.enable)
			setFieldValue('accountName', data.accountName)
			setFieldValue('accountNumber', data.accountNumber)
			setFieldValue('bankCode', data.bankCode)
		}
	}, [data, setFieldValue])

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">แก้ไขธนาคาร</h3>
				</div>
				<form className="space-y-3 mt-5" autoComplete="off" onSubmit={handleSubmit}>
					<Input
						type="text"
						label="ชื่อบัญชี"
						name="accountName"
						value={values.accountName}
						required
						onChange={handleChange}
					/>
					<Input
						type="text"
						label="เลขที่บัญชี"
						name="accountNumber"
						value={values.accountNumber}
						required
						onChange={handleChange}
					/>
					<Select
						name="bankCode"
						label="ธนาคาร"
						value={values.bankCode}
						required
						onChange={handleChange}
					>
						{banks.map((bank) => (
							<option key={bank.bankCode} value={bank.bankCode}>
								{bank.bankName}
							</option>
						))}
					</Select>
					<div className="bg-gray-100 p-3 rounded-md">
						<Checkbox
							name="enable"
							label="เปิดใช้งาน"
							checked={values.enable}
							onChange={handleChange}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4">
						<Button type="submit" disabled={isSubmitting}>
							ยืนยัน
						</Button>
						<Button
							type="button"
							color="text-gray-900 border border-gray-200 hover:border-gray-300"
							onClick={() => onClose()}
						>
							ยกเลิก
						</Button>
					</div>
				</form>
			</div>
		</CustomDialog>
	)
}

export default UpdateBankDialog
