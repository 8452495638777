import { CurrencyDollarIcon, HomeIcon, UserGroupIcon } from '@heroicons/react/outline'
import React, { ComponentProps, FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAuthContext } from '../../contexts/authContext'
import MobileSidebar from './MobileSidebar'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

export interface MenuItem {
	icon: (props: ComponentProps<'svg'>) => JSX.Element
	to: string
	text: string
	role: string
}
export const menuItems: MenuItem[] = [
	{
		icon: HomeIcon,
		to: '/dashboard',
		text: 'หน้าแรก',
		role: 'staff',
	},
	{
		icon: CurrencyDollarIcon,
		to: '/banks',
		text: 'ธนาคาร',
		role: 'admin',
	},
	{
		icon: UserGroupIcon,
		to: '/users',
		text: 'ผู้ใช้งาน',
		role: 'master',
	},
]
const Layout: FC = ({ children }) => {
	const history = useHistory()
	const [openSidebar, setOpenSidebar] = useState(false)
	useEffect(() => {
		setOpenSidebar(false)
	}, [history.location])

	const { authInfo, signOut } = useAuthContext()
	if (!authInfo) {
		return null
	}
	return (
		<div className="min-h-screen bg-gray-100">
			{authInfo && (
				<Topbar
					openSidebar={openSidebar}
					setOpenSidebar={setOpenSidebar}
					userInfo={authInfo}
					signOut={signOut}
				/>
			)}

			<div className="py-4 px-4 xl:px-0 xl:py-6">
				<div className="max-w-7xl mx-auto grid grid-cols-12 gap-8">
					<Sidebar user={authInfo} />
					<MobileSidebar
						openSidebar={openSidebar}
						setOpenSidebar={setOpenSidebar}
						user={authInfo}
					/>

					{children}
				</div>
			</div>
		</div>
	)
}

export default Layout
