import React, { Dispatch, FC, SetStateAction } from 'react'
import { Pagination as _Pagination } from '../../adapters/pagination'
import { numberFormat } from '../../helpers/number'
import Button from '../Button'

interface PaginationProps {
	data: _Pagination

	page: number
	setPage: Dispatch<SetStateAction<number>>
}
const Pagination: FC<PaginationProps> = ({ data, page, setPage }) => {
	const _from = 1 + (page - 1) * data.limit
	const _to = page * data.limit

	const from = numberFormat(data.rows <= 0 ? 0 : _from, 0)
	const to = numberFormat(_to > data.rows ? data.rows : _to, 0)
	const of = numberFormat(data.rows, 0)

	return (
		<nav className="py-3 px-6 flex items-center justify-between border-t border-gray-200">
			<Button
				type="button"
				size="sm"
				color="bg-white border text-gray-900 hover:bg-gray-50"
				onClick={() => setPage((page) => page - 1)}
				disabled={page === 1}
			>
				Previous
			</Button>
			<span className="text-sm text-gray-500">
				Showing <b>{from}</b> to <b>{to}</b> of <b>{of}</b> entries
			</span>
			<Button
				type="button"
				size="sm"
				color="bg-white border text-gray-900 hover:bg-gray-50"
				onClick={() => setPage((page) => page + 1)}
				disabled={page >= (data.pageSize || 0)}
			>
				Next
			</Button>
		</nav>
	)
}

export default Pagination
