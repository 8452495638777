import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { updateUser } from '../../adapters/user'
import PrimaryButton from '../../components/Button'
import SecondaryButton from '../../components/Button/SecondaryButton'
import Checkbox from '../../components/Checkbox'
import CustomDialog from '../../components/Dialog/CustomDialog'
import Input from '../../components/Input'
import Select from '../../components/Select'
import { useToastContext } from '../../contexts/toastContext'
import { UserInfo } from '../../entities/user'

interface UpdateUserDialogProps {
	open: boolean
	onClose: (v: boolean) => void

	user: UserInfo | undefined
	refresh: () => void
}
const UpdateUserDialog: FC<UpdateUserDialogProps> = ({ open, onClose, user, refresh }) => {
	const { addToast } = useToastContext()
	const { values, isSubmitting, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			name: '',
			username: '',
			password: '',
			role: '',
			access: {
				game: false,
				huay: false,
			},
		},
		onSubmit: async (values) => {
			if (!user) {
				return false
			}

			try {
				const res = await updateUser(user.id, values)

				addToast({
					variant: 'success',
					message: res.message,
				})
				refresh()
				onClose(false)
			} catch ({ message }) {
				addToast({
					variant: 'error',
					message: message,
				})
			}
		},
	})
	useEffect(() => {
		if (open && user) {
			setFieldValue('name', user.name)
			setFieldValue('username', user.username)
			setFieldValue('role', user.role)
			setFieldValue('access.game', user.access.game || false)
			setFieldValue('access.huay', user.access.huay || false)
		}
	}, [open, user, setFieldValue])

	return (
		<CustomDialog open={open} onClose={onClose}>
			<div className="w-full sm:w-1/4 bg-white rounded-md p-6 transform">
				<div className="text-center">
					<h3 className="text-lg text-gray-900">แก้ไขข้อมูลผู้ใช้งาน</h3>
				</div>
				<form className="mt-5 space-y-3" autoComplete="off" onSubmit={handleSubmit}>
					<Input
						type="text"
						label="ชื่อ"
						name="name"
						value={values.name}
						required
						onChange={handleChange}
					/>
					<Input
						type="text"
						label="ผู้ใช้งาน"
						name="username"
						value={values.username}
						disabled
						required
						onChange={handleChange}
					/>
					<Input type="text" label="รหัสผ่าน" name="password" onChange={handleChange} />
					<Select
						label="สถานะ"
						name="role"
						value={values.role}
						required
						onChange={handleChange}
					>
						<option value="master">Master</option>
						<option value="admin">Admin</option>
						<option value="staff">Staff</option>
					</Select>
					<div className="bg-gray-100 p-3 rounded-md">
						<Checkbox
							name="access.game"
							label="Game"
							checked={values.access.game}
							onChange={handleChange}
						/>
						<Checkbox
							name="access.huay"
							label="Huay"
							checked={values.access.huay}
							onChange={handleChange}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4">
						<PrimaryButton type="submit" disabled={isSubmitting}>
							ยืนยัน
						</PrimaryButton>
						<SecondaryButton type="button" onClick={() => onClose(false)}>
							ยกเลิก
						</SecondaryButton>
					</div>
				</form>
			</div>
		</CustomDialog>
	)
}

export default UpdateUserDialog
