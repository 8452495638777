import React, { Fragment, useState } from 'react'
import { useAuthContext } from '../../contexts/authContext'
import { Bank } from '../../entities/bank'
import { SCB } from '../../entities/scb'
import DeleteBankDialog from './BankDialog/DeleteBankDialog'
import UpdateBankDialog from './BankDialog/UpdateBankDialog'
import BankList from './BankList'
import AddSCBDialog from './SCBDialog/AddSCBDialog'
import ConfirmSCBDialog from './SCBDialog/ConfirmSCBDialog'
import DeleteSCBDialog from './SCBDialog/DeleteSCBDialog'
import SCBList from './SCBList'

const BanksLanding = () => {
	const { authInfo: user } = useAuthContext()

	// update bank
	const [openBankUpdateDialog, setOpenBankUpdateDialog] = useState(false)
	const [toUpdateBank, setToUpdateBank] = useState<Bank | undefined>(undefined)
	const onUpdateBank = (bank: Bank) => {
		setOpenBankUpdateDialog(true)
		setToUpdateBank(bank)
	}

	// delete bank
	const [openBankDeleteDialog, setOpenBankDeleteDialog] = useState(false)
	const [toDeleteBank, setToDeleteBank] = useState<Bank | undefined>(undefined)
	const onDeleteBank = (bank: Bank) => {
		setOpenBankDeleteDialog(true)
		setToDeleteBank(bank)
	}

	// add scb
	const [openAddSCBDialog, setOpenAddSCBDialog] = useState(false)
	const onAddSCB = () => {
		setOpenAddSCBDialog(true)
	}

	// confirm scb
	const [openConfirmSCBDialog, setOpenConfirmSCBDialog] = useState(false)
	const [toConfirmSCB, setToConfirmSCB] = useState('')
	const onConfirmSCB = (deviceID: string) => {
		setOpenConfirmSCBDialog(true)
		setToConfirmSCB(deviceID)
	}

	// delete scb
	const [openDeleteSCBDialog, setOpenDeleteSCBDialog] = useState(false)
	const [toDeleteSCB, setToDeleteSCB] = useState<SCB | undefined>(undefined)
	const onDeleteSCB = (scb: SCB) => {
		setOpenDeleteSCBDialog(true)
		setToDeleteSCB(scb)
	}

	if (!user) {
		return null
	}
	return (
		<Fragment>
			<main className="col-span-12 lg:col-span-5 space-y-3">
				{user.access.game && (
					<BankList type="game" onUpdateBank={onUpdateBank} onDeleteBank={onDeleteBank} />
				)}

				{user.access.huay && (
					<BankList type="huay" onUpdateBank={onUpdateBank} onDeleteBank={onDeleteBank} />
				)}
			</main>
			<aside className="col-span-12 lg:col-span-5 space-y-3">
				<SCBList onAddSCB={onAddSCB} onDeleteSCB={onDeleteSCB} />
			</aside>

			{/* Bank Dialog */}
			<UpdateBankDialog
				open={openBankUpdateDialog}
				onClose={() => setOpenBankUpdateDialog(false)}
				data={toUpdateBank}
			/>
			<DeleteBankDialog
				open={openBankDeleteDialog}
				onClose={() => setOpenBankDeleteDialog(false)}
				data={toDeleteBank}
			/>

			{/* SCB Dialog */}
			<AddSCBDialog
				open={openAddSCBDialog}
				onClose={() => setOpenAddSCBDialog(false)}
				onConfirmSCB={onConfirmSCB}
			/>
			<ConfirmSCBDialog
				open={openConfirmSCBDialog}
				onClose={() => setOpenConfirmSCBDialog(false)}
				deviceID={toConfirmSCB}
			/>

			<DeleteSCBDialog
				open={openDeleteSCBDialog}
				onClose={() => setOpenDeleteSCBDialog(false)}
				data={toDeleteSCB}
			/>
		</Fragment>
	)
}

export default BanksLanding
