const roleToNumber = (role: string) => {
	switch (role) {
		case 'master': {
			return 100
		}
		case 'admin': {
			return 50
		}
		default: {
			return 0
		}
	}
}

export const isRoleAccess = (currentRole: string, requireRole: string) =>
	roleToNumber(currentRole) >= roleToNumber(requireRole)
