import { SCB } from '../entities/scb'
import api, { BaseResponse, ResourcePage } from './index'

export const getAllSCBs = (params: any) => {
	return api.get<void, ResourcePage<SCB>>('/v1/scb', { params })
}

interface requestSCBPayload {
	identification: string
	brithDate: string
	phone: string
	accountNumber: string
	pin: string
}
interface requestSCBResponse {
	message: string
	data: {
		deviceID: string
	}
}
export const requestSCB = (payload: requestSCBPayload) => {
	return api.post<void, requestSCBResponse>('/v1/scb', payload)
}

interface confirmSCBPayload {
	otp: string
}
export const confirmOTP = (deviceID: string, payload: confirmSCBPayload) => {
	return api.post<void, BaseResponse>(`/v1/scb/${deviceID}`, payload)
}

export const deleteSCB = (id: number) => {
	return api.delete<void, BaseResponse>(`/v1/scb/${id}`)
}
