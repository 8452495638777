import React, { Fragment, useState } from 'react'
import { Transfer } from '../../entities/transfer'
import ConfirmTransferDialog from './Dialog/ConfirmTransferDialog'
import CreateTransferDialog from './Dialog/CreateTransferDialog'
import RejectTransferDialog from './Dialog/RejectTransferDialog'
import StatList from './StatList'
import TransferList from './TransferList'

const DashboardLanding = () => {
	// create transfer
	const [openCreateTransferDialog, setOpenCreateTransferDialog] = useState(false)

	// confirm transfer
	const [openConfirmTransferDialog, setOpenConfirmTransferDialog] = useState(false)
	const [toConfirmTransfer, setToConfirmTransfer] = useState<Transfer | undefined>(undefined)
	const onConfirmTransfer = (transfer: Transfer) => {
		setOpenConfirmTransferDialog(true)
		setToConfirmTransfer(transfer)
	}

	// reject transfer
	const [openRejectTransferDialog, setOpenRejectTransferDialog] = useState(false)
	const [toRejectTransfer, setToRejectTransfer] = useState<Transfer | undefined>(undefined)
	const onRejectTransfer = (transfer: Transfer) => {
		setOpenRejectTransferDialog(true)
		setToRejectTransfer(transfer)
	}

	return (
		<Fragment>
			<main className="col-span-12 lg:col-span-10">
				<StatList />
				<TransferList
					onCreateTransfer={() => setOpenCreateTransferDialog(true)}
					onConfirmTransfer={onConfirmTransfer}
					onRejectTransfer={onRejectTransfer}
				/>
			</main>

			<CreateTransferDialog
				open={openCreateTransferDialog}
				onClose={() => setOpenCreateTransferDialog(false)}
			/>
			<ConfirmTransferDialog
				open={openConfirmTransferDialog}
				onClose={() => setOpenConfirmTransferDialog(false)}
				data={toConfirmTransfer}
			/>
			<RejectTransferDialog
				open={openRejectTransferDialog}
				onClose={() => setOpenRejectTransferDialog(false)}
				data={toRejectTransfer}
			/>
		</Fragment>
	)
}

export default DashboardLanding
