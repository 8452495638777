import { Bank } from '../entities/bank'
import api, { BaseResponse, ResourcePage } from './index'

export const getBanks = (params: any) => {
	return api.get<void, ResourcePage<Bank>>(`/v1/banks`, { params: params })
}

interface AddBankPayload {
	type: string
	accountNumber: string
	accountName: string
	bankCode: string
}
export const addBank = (payload: AddBankPayload) => {
	return api.post<void, BaseResponse>('/v1/banks', payload)
}

interface UpdateBankPayload {
	enable: boolean
	accountNumber: string
	accountName: string
	bankCode: string
}
export const updateBank = (id: number, payload: UpdateBankPayload) => {
	return api.patch<void, BaseResponse>(`/v1/banks/${id}`, payload)
}

export const deleteBank = (id: number) => {
	return api.delete<void, BaseResponse>(`/v1/banks/${id}`)
}
