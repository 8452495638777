import clsx from 'clsx'
import React, { ButtonHTMLAttributes, FC } from 'react'
import { ButtonProps } from '.'

const Button: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className,
	size = 'md',
	color = 'bg-indigo-600 hover:bg-indigo-700',
	...props
}) => {
	return (
		<button
			{...props}
			className={clsx(
				className,
				{
					'px-4 py-2 text-sm font-light': size === 'md',
					'px-3 py-1.5 text-xs font-light': size === 'sm',
				},
				color,
				'block rounded-md text-white tracking-wide focus:outline-none disabled:opacity-50'
			)}
		>
			{children}
		</button>
	)
}

export default Button
