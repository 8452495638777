import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { debounce } from 'lodash'
import React, { FC, useState } from 'react'
import useSWR from 'swr'
import { getAllSCBs } from '../../adapters/scb'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Pagination from '../../components/Pagination'
import { SCB } from '../../entities/scb'

interface SCBListProps {
	onAddSCB: () => void
	onDeleteSCB: (scb: SCB) => void
}
const SCBList: FC<SCBListProps> = ({ onAddSCB, onDeleteSCB }) => {
	const [query, setQuery] = useState('')
	const setDebounceQuery = debounce((v: string) => {
		setQuery(v)
	}, 500)

	const [page, setPage] = useState(1)
	const { data: res } = useSWR(`/v1/scb?query=${query}&page=${page}`, () =>
		getAllSCBs({
			query: query,
			page: page,
		})
	)

	const renderSCBRow = (scb: SCB) => {
		return (
			<li key={scb.id + scb.accountNumber}>
				<div className="px-6 py-4 flex items-center justify-between">
					<div className="flex flex-1 items-center min-w-0">
						<span>
							{scb.enable ? (
								<CheckCircleIcon className="w-6 h-6 text-green-500" />
							) : (
								<XCircleIcon className="w-6 h-6 text-red-500" />
							)}
						</span>
						<div className="ml-4 truncate">
							<h3 className="text-indigo-500 truncate">{scb.accountName}</h3>
							<h6 className="text-gray-500 text-sm">{scb.accountNumber}</h6>
						</div>
					</div>
					<div className="flex flex-1 flex-row justify-end">
						<Button
							type="button"
							className="ml-2"
							size="sm"
							color="bg-red-600 hover:bg-red-700"
							onClick={() => onDeleteSCB(scb)}
						>
							ลบ
						</Button>
					</div>
				</div>
			</li>
		)
	}
	const renderTableContent = () => {
		if (!res) {
			return (
				<li>
					<div className="px-6 py-4 text-center">
						<div className="w-6 h-6 spinner border-2" />
					</div>
				</li>
			)
		}
		if (res.data.length <= 0) {
			return (
				<li>
					<div className="px-6 py-4 text-center">ไม่พบข้อมูล</div>
				</li>
			)
		}
		return res.data.map((row) => renderSCBRow(row))
	}
	return (
		<section>
			<div className="bg-white border border-gray-200 rounded-md w-full">
				<div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
					<h3 className="text-lg font-medium">SCB APIs</h3>
					<div className="flex">
						<Input
							placeholder="Search"
							onChange={(e) => setDebounceQuery(e.currentTarget.value)}
						/>
						<Button type="button" className="ml-3" onClick={onAddSCB}>
							เพิ่มบัญชี
						</Button>
					</div>
				</div>
				<ul className="divide-y divide-gray-200">{renderTableContent()}</ul>
				{res?.pagination && (
					<Pagination data={res.pagination} page={page} setPage={setPage} />
				)}
			</div>
		</section>
	)
}

export default SCBList
