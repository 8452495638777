import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import debounce from 'lodash/debounce'
import React, { FC, useState } from 'react'
import useSWR from 'swr'
import { getBanks } from '../../adapters/bank'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Pagination from '../../components/Pagination'
import { Bank } from '../../entities/bank'
import { getBankName } from '../../helpers/bank'
import AddBankDialog from './BankDialog/AddBankDialog'

interface BankListProps {
	type: string

	onUpdateBank: (v: Bank) => void
	onDeleteBank: (v: Bank) => void
}
const BankList: FC<BankListProps> = ({ type, onUpdateBank, onDeleteBank }) => {
	const [query, setQuery] = useState('')
	const setDebounceQuery = debounce((v: string) => {
		setQuery(v)
	}, 500)

	const [page, setPage] = useState(1)
	const { data: res, mutate } = useSWR(`/v1/banks?type=${type}&query=${query}&page=${page}`, () =>
		getBanks({
			type: type,
			query: query,
			page: page,
		})
	)

	// add bank dialog
	const [addBankDialogOpen, setAddBankDialogOpen] = useState(false)

	const renderBankRow = (bank: Bank) => {
		return (
			<li key={bank.id + bank.accountNumber}>
				<div className="px-6 py-4 flex items-center justify-between">
					<div className="flex flex-1 items-center min-w-0">
						<span>
							{bank.enable ? (
								<CheckCircleIcon className="w-6 h-6 text-green-500" />
							) : (
								<XCircleIcon className="w-6 h-6 text-red-500" />
							)}
						</span>
						<div className="ml-4 truncate">
							<h3 className="text-indigo-500 truncate">{bank.accountName}</h3>
							<h6 className="text-gray-500 text-sm">{bank.accountNumber}</h6>
						</div>
					</div>
					<div className="flex flex-1 justify-center">
						<span className="bg-indigo-100 text-indigo-600 px-2 py-1 rounded-full text-sm text-center">
							{getBankName(bank.bankCode)}
						</span>
					</div>
					<div className="flex flex-1 flex-row justify-end">
						<Button
							type="button"
							className="ml-0"
							size="sm"
							color="bg-gray-600 hover:bg-gray-700"
							onClick={() => onUpdateBank(bank)}
						>
							แก้ไข
						</Button>
						<Button
							type="button"
							className="ml-2"
							size="sm"
							color="bg-red-600 hover:bg-red-700"
							onClick={() => onDeleteBank(bank)}
						>
							ลบ
						</Button>
					</div>
				</div>
			</li>
		)
	}
	const renderTableContent = () => {
		if (!res) {
			return (
				<li>
					<div className="px-6 py-4 text-center">
						<div className="w-6 h-6 spinner border-2" />
					</div>
				</li>
			)
		}
		if (res.data.length <= 0) {
			return (
				<li>
					<div className="px-6 py-4 text-center">ไม่พบข้อมูล</div>
				</li>
			)
		}
		return res.data.map((row) => renderBankRow(row))
	}
	return (
		<section>
			<div className="bg-white border border-gray-200 rounded-md w-full">
				<div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
					<h3 className="text-lg font-medium">ธนาคาร ({type.toUpperCase()})</h3>
					<div className="flex">
						<Input
							placeholder="Search"
							onChange={(e) => setDebounceQuery(e.currentTarget.value)}
						/>
						<Button
							type="button"
							className="ml-3"
							onClick={() => setAddBankDialogOpen(true)}
						>
							เพิ่มบัญชี
						</Button>
					</div>
				</div>
				<ul className="divide-y divide-gray-200">{renderTableContent()}</ul>
				{res?.pagination && (
					<Pagination data={res.pagination} page={page} setPage={setPage} />
				)}
			</div>

			<AddBankDialog
				open={addBankDialogOpen}
				onClose={() => setAddBankDialogOpen(false)}
				type={type}
				refresh={mutate}
			/>
		</section>
	)
}

export default BankList
