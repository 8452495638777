import { UserInfo } from '../entities/user'
import api, { BaseResponse, ResourcePage } from './index'

export const getAllUsers = (params: any) => {
	return api.get<void, ResourcePage<UserInfo>>('/v1/users', {
		params: params,
	})
}

interface CreateUserPayload {
	name: string
	username: string
	password: string
}
export const createUser = (payload: CreateUserPayload) => {
	return api.post<void, BaseResponse>('/v1/users', payload)
}

interface UpdateUserPayload {
	name: string
	password: string
	role: string
	access: Record<'game' | 'huay', boolean>
}
export const updateUser = (id: number, payload: UpdateUserPayload) => {
	return api.patch<void, BaseResponse>(`/v1/users/${id}`, payload)
}
