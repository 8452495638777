import { UserInfo } from '../entities/user'
import api, { BaseResponse } from './index'

export interface LoginPayload {
	username: string
	password: string
}
export interface LoginResponse {
	message: string
	token: string
}
export const login = (payload: LoginPayload) => {
	return api.post<void, BaseResponse & LoginResponse>('/v1/auth/login', payload)
}

export const fetchCurrentUser = () => {
	return api.get<void, UserInfo>('/v1/auth/me')
}
