import { CheckCircleIcon, ClockIcon, XCircleIcon } from '@heroicons/react/solid'
import React, { FC, useState } from 'react'
import useSWR from 'swr'
import { getAllTransfers } from '../../adapters/transfer'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import { Transfer } from '../../entities/transfer'
import { numberFormat } from '../../helpers/number'

interface TransferListProps {
	onCreateTransfer: () => void
	onConfirmTransfer: (transfer: Transfer) => void
	onRejectTransfer: (tranfer: Transfer) => void
}
const TransferList: FC<TransferListProps> = ({
	onCreateTransfer,
	onConfirmTransfer,
	onRejectTransfer,
}) => {
	const [page, setPage] = useState(1)
	const { data: res } = useSWR(`/v1/transfers?page=${page}`, () =>
		getAllTransfers({
			page: page,
		})
	)

	const renderRowIcon = (transfer: Transfer) => {
		switch (transfer.status) {
			case 'success': {
				return <CheckCircleIcon className="h-6 w-6 text-green-500" />
			}
			case 'rejected': {
				return <XCircleIcon className="h-6 w-6 text-red-500" />
			}
			default: {
				return <ClockIcon className="h-6 w-6 text-indigo-500" />
			}
		}
	}
	const renderTableRow = (transfer: Transfer) => {
		return (
			<tr key={`transfer.${transfer.id}`}>
				<td className="px-6 py-3 whitespace-nowrap">
					<div className="flex items-center">
						{renderRowIcon(transfer)}
						<div className="ml-4">
							<h5 className="font-medium text-gray-900">{transfer.to.accountName}</h5>
							<h6 className="text-sm text-gray-500">{transfer.to.accountNumber}</h6>
						</div>
					</div>
				</td>
				<td className="px-6 py-3 whitespace-nowrap">
					<h5 className="font-medium text-gray-900">{numberFormat(transfer.amount)}</h5>
					<h6 className="text-sm text-gray-500">* หมายเหตุ: {transfer.remark}</h6>
				</td>
				<td className="px-6 py-3 whitespace-nowrap">
					<h5 className="font-medium text-gray-900">{transfer.user.name}</h5>
					<h6 className="text-sm text-gray-500">{transfer.user.username}</h6>
				</td>
				<td className="px-6 py-3 whitespace-nowrap">
					<span className="text-sm text-gray-500">{transfer.createdAt}</span>
				</td>
				<td className="px-6 py-3">
					{transfer.status === 'pending' && (
						<div className="flex">
							<Button
								type="button"
								color="bg-green-500 hover:bg-green-600"
								size="sm"
								onClick={() => onConfirmTransfer(transfer)}
							>
								ยืนยัน
							</Button>
							<Button
								type="button"
								color="bg-red-500 hover:bg-red-600"
								size="sm"
								className="ml-3"
								onClick={() => onRejectTransfer(transfer)}
							>
								ยกเลิก
							</Button>
						</div>
					)}
				</td>
			</tr>
		)
	}

	const renderTableHead = () => (
		<thead className="bg-gray-50">
			<tr>
				<th
					scope="col"
					className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
				>
					บัญชี
				</th>
				<th
					scope="col"
					className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
				>
					จำนวนเงิน
				</th>
				<th
					scope="col"
					className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
				>
					ผู้ทำรายการ
				</th>
				<th
					scope="col"
					className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
				>
					วันที่ทำรายการ
				</th>
				<th scope="col" className="relative px-6 py-3">
					<span className="sr-only">Actions</span>
				</th>
			</tr>
		</thead>
	)
	const renderTable = () => {
		if (!res) {
			return (
				<div className="px-6 py-4 text-center">
					<div className="w-6 h-6 spinner border-2" />
				</div>
			)
		}
		if (res.data.length <= 0) {
			return <div className="px-6 py-4 text-center">ไม่พบข้อมูล</div>
		}
		return (
			<div className="overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200">
					{renderTableHead()}
					<tbody className="bg-white divide-y divide-gray-200">
						{res.data.map((row) => renderTableRow(row))}
					</tbody>
				</table>
			</div>
		)
	}
	return (
		<section>
			<div className="bg-white border border-gray-200 rounded-md w-full">
				<div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
					<h3 className="text-lg font-medium">รายการเบิก</h3>
					<div className="flex">
						<Button type="button" className="ml-3" onClick={onCreateTransfer}>
							ทำรายการเบิก
						</Button>
					</div>
				</div>
				{renderTable()}
				{res?.pagination && (
					<Pagination data={res.pagination} page={page} setPage={setPage} />
				)}
			</div>
		</section>
	)
}

export default TransferList
