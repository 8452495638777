export const banks = [
	{
		bankCode: 'SCB',
		bankName: 'ธนาคารไทยพาณิชย์',
	},
	{
		bankCode: 'BAY',
		bankName: 'ธนาคารกรุงศรีอยุธยา',
	},
	{
		bankCode: 'KTB',
		bankName: 'ธนาคารกรุงไทย',
	},
	{
		bankCode: 'KBANK',
		bankName: 'ธนาคารกสิกรไทย',
	},
]

export const getBankName = (bankCode: string) => {
	const bank = banks.find((x) => x.bankCode === bankCode)
	if (bank) {
		return bank.bankName
	}
	return bankCode
}
