import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'

interface Toast {
	variant: 'success' | 'error'
	message: string
	key?: string
}
interface IToastContext {
	toasts: Toast[]
	addToast: (v: Toast) => void
}

export const ToastContext = createContext<IToastContext>({
	toasts: [],
	addToast: (_) => {},
})
export const useToastContext = () => useContext(ToastContext)

export const ToastContextProvider: FC = ({ children }) => {
	const [toasts, setToasts] = useState<Toast[]>([])
	const addToast = useCallback(
		(v: Toast) => {
			v.key = `${v.message}-${Math.random()}`
			setToasts((_toasts) => [..._toasts, v])
		},
		[setToasts]
	)

	const initToastContext = {
		toasts: toasts,
		addToast: addToast,
	}
	useEffect(() => {
		if (toasts.length > 0) {
			const timeoutID = setTimeout(() => {
				setToasts((_toasts) => _toasts.slice(1))
			}, 3000)
			return () => clearTimeout(timeoutID)
		}
	}, [toasts])
	return (
		<ToastContext.Provider value={initToastContext}>
			{children}

			<div className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:items-start z-50">
				<div className="w-full flex flex-col items-center space-y-4 sm:items-end">
					{toasts.map((t) => (
						<div
							key={t.key}
							className={clsx('max-w-sm w-full rounded-md overflow-hidden p-4', {
								'bg-green-50 text-green-500': t.variant === 'success',
								'bg-red-50 text-red-500': t.variant === 'error',
							})}
						>
							<div className="flex items-center">
								<div className="flex-shrink-0">
									{t.variant === 'success' ? (
										<CheckCircleIcon className="h-6 w-6" />
									) : (
										<ExclamationCircleIcon className="h-6 w-6" />
									)}
								</div>
								<div className="ml-3 flex-1 mt-0.5">
									<p className="text-sm texct-gray-500">{t.message}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</ToastContext.Provider>
	)
}
