import clsx from 'clsx'
import React, { ButtonHTMLAttributes, FC } from 'react'

const SecondaryButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className,
	...props
}) => {
	return (
		<button
			{...props}
			className={clsx(
				className,
				'block px-4 py-2 rounded-md text-sm font-medium text-gray-900 border border-gray-200 hover:border-gray-300 focus:outline-none disabled:opacity-50'
			)}
		>
			{children}
		</button>
	)
}

export default SecondaryButton
