import { MenuIcon, XIcon } from '@heroicons/react/solid'
import React, { FC } from 'react'
import { UserInfo } from '../../entities/user'
import Button from '../Button'

interface TopbarProps {
	openSidebar: boolean
	setOpenSidebar: (v: boolean) => void

	userInfo: UserInfo
	signOut: () => void
}
const Topbar: FC<TopbarProps> = ({
	openSidebar,
	setOpenSidebar,

	userInfo,
	signOut,
}) => (
	<div className="bg-white">
		<div className="max-w-7xl mx-auto px-4 py-6 lg:px-0 lg:py-4">
			<div className="relative flex justify-between">
				<div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
					<div className="flex-shrink-0 flex items-center">Central289</div>
				</div>
				<div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
					<div className="rounded-sm text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
						{openSidebar ? (
							<XIcon
								className="block h-6 w-6"
								onClick={() => setOpenSidebar(false)}
							/>
						) : (
							<MenuIcon
								className="block h-6 w-6"
								onClick={() => setOpenSidebar(true)}
							/>
						)}
					</div>
				</div>
				<div className="hidden lg:flex lg:items-center lg:justify-end">
					<div className="flex items-center">
						<div className="ml-3">
							<h5 className="text-sm text-indigo-500 font-bold">{userInfo.name}</h5>
							<h6 className="text-xs text-gray-500">{userInfo.role.toUpperCase()}</h6>
						</div>
					</div>
					<Button
						type="button"
						color="bg-red-500 hover:bg-red-600"
						className="ml-5"
						onClick={signOut}
					>
						ออกจากระบบ
					</Button>
				</div>
			</div>
		</div>
	</div>
)

export default Topbar
