import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import './app.scss'
import { AuthContextProvider } from './contexts/authContext'
import BanksLanding from './features/Banks'
import DashboardLanding from './features/Dashboard/DashboardLanding'
import Login from './features/Login'
import UsersLanding from './features/Users/UsersLanding'

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/login" component={Login} />

				<AuthContextProvider>
					<Switch>
						<Route path="/dashboard" component={DashboardLanding} />
						<Route path="/banks" component={BanksLanding} />
						<Route path="/users" component={UsersLanding} />

						<Redirect to="/login" />
					</Switch>
				</AuthContextProvider>
			</Switch>
		</Router>
	)
}

export default App
