import React, { FC } from 'react'

interface StatProps {
	textColor?: string
    value?: string
	title?: string
}
const Stat: FC<StatProps> = ({
	textColor,
    value,
    title
}) => (
    <div className="bg-white border border-gray-200 rounded-md w-full">
        <div className="px-6 py-4 border-b border-gray-200">
            <span className={'block text-xl sm:text-2xl font-bold ' + textColor}>{value}</span>
            <span className="block text-gray-500">{title}</span>
        </div>
    </div>
)

export default Stat
